import React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Smile from '../images/smile.svg'
import Smilesmall from '../images/smile-small.svg'
import CirclesBg from '../images/circles-bg.svg'
import RecordIcon from '../images/record-video-icon.svg'
import GroupIcon from '../images/group-icon.svg'
import ShareIcon from '../images/share-video-icon.svg'
import BackgroundBoy from '../components/bgimage'
import BackgroundSisters from '../components/bgimagesisters'
import BackgroundGrandpa from '../components/bgimagegrandpa'
import Header from '../components/header'
import Cards from '../components/cards'
import Footer from '../components/footer'

export default ({ data }) => (

  <Layout>
    <Helmet>
      <title>Group video greeting cards - Joycards</title>
      <meta name="description" content="Create group video cards for birthdays, farewells, get well soons, Christmas and other occassions where you want to give more joy than a greeting card." />
    </Helmet>
    <Header />

    <section id='hero' role="main">
      <div className='container two-grid'>
        <div className='hero-left hero-text flex flex-col flex-jc'>
          <h1>
            Group video<br />greeting cards for
            <div className='slider'>
              <div className='mask'>
                <ul>
                  <li className='anim1'>birthdays</li>
                  <li className='anim2'>weddings</li>
                  <li className='anim3'>farewells</li>
                  <li className='anim4'>get well soons</li>
                  <li className='anim5'>sharing joy!</li>
                </ul>
              </div>
            </div>
          </h1>
          <p className='lead mt-0'>Collate video messages from friends, family or coworkers into one memorable, lasting video card that people love.</p>
          <div className='cta-btn-wrapper'>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start your card</a>
            <div className='hero-below-btn'>
              <span>It's free. No credit card required.</span>
            </div>
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-img-wrapper'>
            <div className='bg-circles-top'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
            <div className='hero-img'>
              <Img fluid={data.file.childImageSharp.fluid}
              alt="group video card showing multiple people making videos for a birthday card" />
            </div>
            <div className='smile-img'>
              <img src={Smile} alt='smile icon' aria-hidden="true"/>
            </div>
            <div className='bg-circles-side'>
              <img src={CirclesBg} alt='circles for decoration' aria-hidden="true"/>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='beige-bg section-wrapper-lg'>
      <div className='container'>
        <div className='highlight-wrapper'>
          <h2 className='text-center'>Create your video card in 3 easy steps</h2>
          <div className='flex flex-jc flex-wrap mt-2 text-center'>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={RecordIcon} alt='record video icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Record your video</h4>
                <p className='lead mt-05'>Record your own personal video message.</p>
              </div>
            </div>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={GroupIcon} alt='group of people icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Invite family &amp; friends</h4>
                <p className='lead mt-05'>Send invites to family and friends so they can contribute to your group card.</p>
              </div>
            </div>
            <div className='highlight'>
              <div className='highlight-head'>
                <img src={ShareIcon} alt='share video icon' aria-hidden="true"/>
              </div>
              <div className='highlight-body'>
                <h4 className='mt-1'>Share the joy</h4>
                <p className='lead mt-05'>When your card is ready, send it to the recipient and share some joy.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='beige-bg feature-wrapper'>
      <div className='container'>
        <div className='two-grid-features feature-box'>
          <div className='feature-img'>
            <BackgroundBoy />
          </div>
          <div className='feature-content flex flex-jc flex-ac flex-col'>
            <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            <div className='feature-text'>
              <h2>One card,<br />many videos</h2>
              <p>Create more memorable, and lasting, virtual greeting cards that people love.</p>
            </div>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
          </div>
        </div>

        <div className='two-grid-features grid-reversed feature-box feature-mid'>
          <div className='push'>
            <div className='feature-content flex flex-jc flex-ac flex-col'>
              <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
              <div className='feature-text'>
                <h2>Invite<br />family, friends &amp; colleagues</h2>
                <p>Get your family to make a birthday video card for Grandma or add 50 colleagues and make a farewell video card for a staff member leaving the team.</p>
              </div>
              <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
            </div>
          </div>
          <div className='pull feature-img'>
            <BackgroundSisters />
          </div>
        </div>

        <div className='two-grid-features feature-box'>
          <div className='feature-img'>
            <BackgroundGrandpa />
          </div>
          <div className='feature-content flex flex-jc flex-ac flex-col'>
            <img src={Smilesmall} alt='smile icon' aria-hidden="true"/>
            <div className='feature-text'>
              <h2>More joy than a greeting card</h2>
              <p>Joycards last forever. They're more personal, and more loved, than traditional paper cards and eCards.</p>
            </div>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='feature-link'>Start your card</a>
          </div>
        </div>
      </div>
    </section>
    <section className='cta'>
      <div className='container flex flex-jc'>
        <div className='cta-btn-wrapper'>
          <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer" className='btn btn-teal btn-lg cta-btn'>Start Your Card - Free</a>
        </div>
      </div>
    </section>

    <Cards />

    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "group-video-card.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800 quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
